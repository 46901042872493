<template>
  <Teleport to="body">
    <div class="overlay" @click="close"></div>
    <div ref="modal" class="modal" tabindex="-1" role="dialog" @keyup.esc="close">
      <div class="modal-content">
        <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
        <div class="modal-header" v-if="title">
          <h5 class="modal-title">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <slot/>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>

export default {
  name: 'Modal',
  emits: ['close'],
  props: {
    title: {
      type: String,
    }
  },
  mounted() {
    this.$refs.modal.focus();
  },
  setup (props, { emit }) {
    function close () {
      emit('close')
    }

    return {
      close
    }
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  background: rgba(0, 0, 0, .5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}

.modal {
  display: block;
  position: fixed;
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  max-height: 90%;
  z-index: 999;
  max-width: 70%;
}

.btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}
</style>