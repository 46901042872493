import {
  allstarPrivacyPolicy,
  allstarTermsConditions, cardsToHide,
  dieselPrivacyPolicy,
  fuelCardPrivacyPolicy,
  fuelCardTermsConditions,
  keyfuelsPrivacyPolicy, notRequiredCardType,
  notRequiredExpectedMonthlySpendFuel, notRequiredNumberOfCards, notRequiredOnlineAccountText,
  notRequiredPetrol, notRequiredPurchaseOptions,
  notRequiredQA,
  notRequiredSpecial,
  notRequiredTermsConditionsField,
  notRequiredZeroLiability,
  purchaseDieselOnly,
  requiredTermsConditionsPopup
} from '@/utils/enums/card-enums'

const checkIsDieselPrivacyPolicy = (cardId) => dieselPrivacyPolicy.some(val => val === cardId)
const checkAllstarPrivacyPolicy = (cardId) => allstarPrivacyPolicy.some(val => val === cardId)
const checkFuelCardPrivacyPolicy = (cardId) => fuelCardPrivacyPolicy.some(val => val === cardId)
const checkKeyFuelsPrivacyPolicy = (cardId) => keyfuelsPrivacyPolicy.some(val => val === cardId)

const getPrivacyLink = (cardId) => {
  switch (true) {
    case checkIsDieselPrivacyPolicy(cardId): {
      return 'https://dieseladvance.co.uk/privacy-policy'
    }

    case checkAllstarPrivacyPolicy(cardId): {
      return 'https://allstarcard.co.uk/privacy-policy/'
    }

    case checkFuelCardPrivacyPolicy(cardId): {
      return 'https://fuelcards.co.uk/privacy-policy/'
    }

    case checkKeyFuelsPrivacyPolicy(cardId): {
      return 'https://keyfuels.co.uk/privacy-policy/'
    }

    default: {
      return 'https://www.allstarcard.co.uk/l/privacy-policy/'
    }
  }
}

const checkFuelCardTermsConditions = (cardId) => fuelCardTermsConditions.some(val => val === cardId)
const checkAllStarTermsConditions = (cardId) => allstarTermsConditions.some(val => val === cardId)

const getTermsConditionsLink = (cardId) => {
  switch (true) {
    case checkFuelCardTermsConditions(cardId): {
      return 'https://fuelcards.co.uk/tfc-fee-tariff/'
    }

    case checkAllStarTermsConditions(cardId): {
      return 'https://allstarcard.co.uk/allstar-card-terms-conditions/'
    }
  }
}

const checkIsPetrolRequired = (cardId) => !notRequiredPetrol.some(val => val === cardId)

const checkIsPurchaseDieselOnly = (cardId) => purchaseDieselOnly.some(val => val === cardId)

const checkIsRequiredTermsConditionsPopup = (cardId) => requiredTermsConditionsPopup.some(val => val === cardId)

const checkIsSpecialRequired = (cardId) => !notRequiredSpecial.some(val => val === cardId)

const checkIsQARequired = (cardId) => !notRequiredQA.some(val => val === cardId)

const checkIsZeroLiabilityRequired = (cardId) => !notRequiredZeroLiability.some(val => val === cardId)

const checkIsExpectedMonthlySpendFuelRequired = (cardId) => !notRequiredExpectedMonthlySpendFuel.some(val => val === cardId)

const checkIsTermsConditionsFieldRequired = (cardId) => !notRequiredTermsConditionsField.some(val => val === cardId)

const checkIsCardTypeRequired = (cardId) => !notRequiredCardType.some(val => val === cardId)

const checkIsPurchaseOptionsRequired = (cardId) => !notRequiredPurchaseOptions.some(val => val === cardId)

const checkIsNumberOfCardsRequired = (cardId) => !notRequiredNumberOfCards.some(val => val === cardId)

const checkIsOnlineAccountTextRequired = (cardId) => !notRequiredOnlineAccountText.some(val => val === cardId)

const cardsToShow = (card) => !cardsToHide.some(val => val === card.id)

const getFormattedNumber = (value) => {
  switch (true) {
    case (value < 1000000): {
      return `£ ${ value / 1000 }k+`
    }

    default: {
      return `£ ${ value / 1000000 }m+`
    }
  }
}

export {
  getPrivacyLink,
  checkIsPetrolRequired,
  checkIsPurchaseDieselOnly,
  checkIsSpecialRequired,
  checkIsQARequired,
  checkIsZeroLiabilityRequired,
  checkIsRequiredTermsConditionsPopup,
  getTermsConditionsLink,
  checkIsTermsConditionsFieldRequired,
  getFormattedNumber,
  checkIsExpectedMonthlySpendFuelRequired,
  checkIsCardTypeRequired,
  checkIsPurchaseOptionsRequired,
  checkIsNumberOfCardsRequired,
  checkIsOnlineAccountTextRequired,
  cardsToShow
}