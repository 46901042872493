<template>
  <div class="row align-items-center  justify-content-center">
    <div class="col-12">
      <h1 class="text-center">{{ msg }}</h1>
    </div>
    <div class="col-8">
      <p>
        The application for any of our fuel cards will only take a couple of minutes but you'll need a few things to
        hand before you begin.
      </p>
    </div>
  </div>
  <div class="col-12">
    <div class="row">
      <div v-for="(card, index) in cards" :key="card.id"
           :class="`text-center col-md-2 col-4 mb-2 rounded-3 p-2 ${index === selectedCard ? 'bg-primary' : ''}`"
           style="cursor: pointer" @click.prevent="setSelectedCard(index)">
        <img class="img img-fluid rounded-3 w-100" :src="BASE_URL+card.image" :alt="card.name"/>
        <div :class="`small text-center mt-3 ${index === selectedCard ? 'text-white' : ''}`">{{ card.name }}</div>
      </div>
    </div>
  </div>

  <visa-form
      @submit="onSubmit"
      :sales-people="salesPeople"
      :card="cards[selectedCard]"
      :key="cards[selectedCard].id"
      v-if="cards.length && cards[selectedCard]['form_type'] === 'Visa'"
  ></visa-form>
  <regular-form
      @submit="onSubmit"
      :sales-people="salesPeople"
      :card="cards[selectedCard]"
      :key="cards[selectedCard].id"
      v-if="cards.length && cards[selectedCard]['form_type'] === 'Regular'"
  ></regular-form>
  <div v-if="Object.keys(validationErrors).length !== 0" class="text-danger">
    <p>Some errors have occurred</p>
    <ul>
      <li v-for="(item, index) in validationErrors" :value="item.name" :key="index">{{ item[0] }}</li>
    </ul>
  </div>
</template>

<script>
import VisaForm from './VisaForm.vue'
import RegularForm from './RegularForm'
import axios from 'axios'
import { cardsToShow } from '@/utils/helpers/cards'

export default {
  name: 'FleetcorHub',
  components: {
    VisaForm,
    RegularForm
  },
  props: {
    msg: String
  },
  data () {
    return {
      cards: [],
      salesPeople: [],
      validationErrors: {},
      selectedCard: 0,
      BASE_URL: process.env.VUE_APP_API_ENDPOINT,
      response: {},
    }
  },
  mounted () {
    this.getCards()
    this.getSalesPeople()
  },

  methods: {
    setSelectedCard (index) {
      this.selectedCard = index
    },
    getCards () {
      axios.get(this.BASE_URL + 'api/cards?skinname=tfc2', {
        headers: {
          'X-Authorization': process.env.VUE_APP_FLEETCOR_API_KEY,
        }
      })
          .then((response) => {
            this.cards = response.data.filter(cardsToShow)
          })
          .catch(response => {
            console.log('error' + response.toString())
          })
    },
    getSalesPeople () {

      axios.get(this.BASE_URL + 'api/salespeople', {
        headers: {
          'X-Authorization': process.env.VUE_APP_FLEETCOR_API_KEY,
        }
      })
          .then((response) => {
            this.salesPeople = response.data.data
          })
          .catch(response => {
            console.log('error' + response.toString())
          })
    },

    onSubmit ({ data, url }) {
      this.validationErrors = {}
      axios.post(url, data, {
        headers: {
          'X-Authorization': process.env.VUE_APP_FLEETCOR_API_KEY,
        }
      }).then(response => {
        this.response = response.data
        this.$router.push({
          path: '/thank-you', query: {
            AppRef: this.response.AppRef,
            DDLink: this.response.DDLink
          }
        })
      }).catch(error => {
        this.validationErrors = error.response.data.errors
        console.log('There was an error! ' + JSON.stringify(error.response.data.errors))
      })
    }
  }

}
</script>


