<template>
  <nav class="navbar navbar-light bg-dark text-white py-3">
    <div class="container justify-content-sm-between">
      <div class="navbar-brand">
        <router-link :to="{ name: 'Home'}" class="text-decoration-none text-white text-center">
          <div>
            <img class="logo" alt="fleetcor" src="./assets/logo.svg">
          </div>
          <div class="mt-2 text-">FLEETCOR HUB</div>
        </router-link>
      </div>
    </div>
  </nav>
  <router-view/>

</template>
<script>
export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        document.title = to.meta.title || 'FLEETCORHUB'
      }
    },
  }
}
</script>

<style lang="scss">

#app {

}
</style>
