import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import ThankYou from "@/views/ThankYou.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { title: 'FLEETCORHUB' }
    },
    {
        path: "/thank-you",
        name: "ThankYou",
        component: ThankYou,
        meta: { title: 'FLEETCORHUB | Thank you' }
    },
    {
        path: "/sales-people",
        name: "SalesPeople",
        component: () => import('@/views/SalesPeopleView.vue'),
        meta: { title: 'FLEETCORHUB | Sales people' },
    },
    {
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: () => import('@/views/PageNotFound.vue'),
        meta: { title: 'FLEETCORHUB | 404 Page not found' },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;