<template>
  <div v-if="shouldShow">
    <div class="card">
      <div class="card-header bg-primary text-center text-white">
        <h3>{{ formStepName.name }}</h3>
      </div>
      <div class="card-body px-md-5 py-4">
        <div class="row">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue'

export default {
  setup () {
    // This is a ref injected from FormWizard
    // clones the step index to get the step's index and advances it by 1 for the next step
    // meaning each step gets a index id starting from 1
    const currentIdx = inject('STEP_COUNTER').value++
    // Grabs the live ref to the current form active step
    const formStepIdx = inject('CURRENT_STEP_INDEX')

    const formStepName = inject('CURRENT_STEP_NAME')

    // If this step should be shown
    const shouldShow = computed(() => {
      return currentIdx === formStepIdx.value
    })

    return {
      formStepName,
      shouldShow,
    }
  },
}
</script>