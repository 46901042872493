<template>
  <div>

    <FormWizard :validation-schema="validationSchema" :step-names="stepNames" :initial-data="initialData" :card="card"
                @submit="onSubmit" @next="onNext"
                v-slot="{ errors, values }">
      <FormStep>

        <div class="col-md-6">
          <div class="mb-4">
            <label for="salesperson">
              <div v-if="salesPeople.length === 0" class="spinner-border text-primary" role="status"></div>
              Salesperson</label>
            <Field :class="[{'is-invalid': !!errors.salesperson}, 'form-select']"
                   id="salesperson" name="salesperson" as="select">
              <option v-for="item in salesPeople" :value="item.id" :key="item.id">{{ item.name }}</option>
            </Field>
            <div class="invalid-feedback">{{ errors.salesperson }}</div>
          </div>

          <div class="mb-4">
            <label for="source">Source</label>
            <Field :class="[{'is-invalid': !!errors.source}, 'form-select']" id="source"
                   name="source" as="select">
              <option value="Allstar">Allstar</option>
              <option value="Direct Mail">Direct Mail</option>
              <option value="FSB">FSB</option>
              <option value="Internet">Internet</option>
              <option value="Ipswitch Sales">Ipswitch Sales</option>
              <option value="Knaresborough Sales">Knaresborough Sales</option>
            </Field>
            <div class="invalid-feedback">{{ errors.source }}</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-4 form-check">
            <Field :class="[{'is-invalid': !!errors.promofreecards}, 'form-check-input']"
                   id="promofreecards" name="promofreecards" type="checkbox" :value="true"
                   :unchecked-value="false">
            </Field>
            <label class="form-check-label" for="promofreecards">Promo Free Cards</label>
            <div class="invalid-feedback">{{ errors.promofreecards }}</div>
          </div>

          <div class="mb-4 form-check">
            <Field :class="[{'is-invalid': !!errors.promo1pplreduction}, 'form-check-input']"
                   id="promo1pplreduction" name="promo1pplreduction" type="checkbox"
                   :value="true" :unchecked-value="false">
            </Field>
            <label class="form-check-label" for="promo1pplreduction">Promo 1ppl Discount</label>
            <div class="invalid-feedback">{{ errors.promo1pplreduction }}</div>
          </div>

          <div class="mb-4 form-check">
            <Field :class="[{'is-invalid': !!errors.promo2pplreduction}, 'form-check-input']"
                   id="promo2pplreduction" name="promo2pplreduction" type="checkbox"
                   :value="true" :unchecked-value="false">
            </Field>
            <label class="form-check-label" for="promo2pplreduction">Promo 2ppl Discount</label>
            <div class="invalid-feedback">{{ errors.promo2pplreduction }}</div>
          </div>

          <div class="mb-4 form-check">
            <Field :class="[{'is-invalid': !!errors.promoreferafriend}, 'form-check-input']"
                   id="promoreferafriend" name="promoreferafriend" type="checkbox" :value="true"
                   :unchecked-value="false">
            </Field>
            <label class="form-check-label" for="promoreferafriend">Promo Refer a Friend</label>
            <div class="invalid-feedback">{{ errors.promoreferafriend }}</div>
          </div>

          <div class="mb-4 form-check">
            <Field :class="[{'is-invalid': !!errors.promofreefuel60}, 'form-check-input']"
                   id="promofreefuel60" name="promofreefuel60" type="checkbox" :value="true"
                   :unchecked-value="false">
            </Field>
            <label class="form-check-label" for="promofreefuel60">Promo Free Fuel 60</label>
            <div class="invalid-feedback">{{ errors.promofreefuel60 }}</div>
          </div>
        </div>

        <div class="row step-1">
          <h4 class="mb-4">Please complete the details below. Required fields are marked with an asterisk.</h4>
          <div class="col-md-6">

            <div class="mb-4">
              <div class="form-group has-tooltip">
                <label for="companyFull">Registered company Full Name*</label>
                <Field :class="[{'is-invalid': !!errors.companyFull}, 'form-control']" id="companyFull"
                       name="companyFull" type="text"
                />
                <div class="question-mark" data-title="The full name of the business applying for the fuel card">?</div>
                <div class="invalid-feedback">{{ errors.companyFull }}</div>
              </div>
            </div>

            <div class="mb-4">
              <div class="form-group has-tooltip">
                <label for="title">Title*</label>
                <Field :class="[{'is-invalid': !!errors.title}, 'form-select']" id="title"
                       name="title" as="select">
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                </Field>
                <div class="question-mark" data-title="Enter the full name of the principal applicant">?</div>
                <div class="invalid-feedback">{{ errors.title }}</div>
              </div>
            </div>

            <div class="mb-4">
              <label for="firstName">First Name*</label>
              <Field :class="[{'is-invalid': !!errors.firstName}, 'form-control']" id="firstName"
                     name="firstName" type="text"/>
              <div class="invalid-feedback">{{ errors.firstName }}</div>
            </div>

            <div class="mb-4">
              <label for="lastName">Last Name*</label>
              <Field :class="[{'is-invalid': !!errors.lastName}, 'form-control']" id="lastName"
                     name="lastName" type="text"/>
              <div class="invalid-feedback">{{ errors.lastName }}</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-4">
              <label for="jobTitle">Your Job Title*</label>
              <Field :class="[{'is-invalid': !!errors.jobTitle}, 'form-control']" id="jobTitle"
                     name="jobTitle" type="text"/>
              <div class="invalid-feedback">{{ errors.jobTitle }}</div>
            </div>
            <div class="mb-4">
              <label for="telephone">Telephone*</label>
              <Field :class="[{'is-invalid': !!errors.telephone}, 'form-control']" id="telephone"
                     name="telephone" type="text"/>
              <div class="invalid-feedback">{{ errors.telephone }}</div>
            </div>
            <div class="mb-4">
              <label for="mobile">Mobile</label>
              <Field :class="[{'is-invalid': !!errors.mobile}, 'form-control']" id="mobile"
                     name="mobile" type="text"/>
              <div class="invalid-feedback">{{ errors.mobile }}</div>
            </div>
            <div class="mb-4">
              <label for="email">Email*</label>
              <Field :class="[{'is-invalid': !!errors.email}, 'form-control']" id="email"
                     name="email" type="email"/>
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-4">
              <label class="form-check-label">I understand that by clicking
                'Next' my information will be processed in accordance with the terms of our
                <a class="btn-link popupexternal cboxElement"
                   :href="privacyLink" target="_blank">privacy
                  policy</a>
                and that I may be contacted by the FleetCor
                <a class="btn-link" href=" https://www.fleetcor.com/en/global-sites.html"
                   target="_blank">group of companies</a> in relation to my application.</label>
            </div>
          </div>
        </div>

      </FormStep>

      <FormStep>
        <div class="col-12">
          <h4 class="mb-4">REGISTERED COMPANY ADDRESS</h4>
          <p>(Your cards will be sent to this address)</p>
        </div>
        <div class="col-md-6">

          <div class="mb-4">
            <div class="form-group has-tooltip">
              <label for="companyForCard">Company Name to appear on cards* </label>
              <Field :class="[{'is-invalid': !!errors.companyForCard}, 'form-control']"
                     id="companyForCard" name="companyForCard" type="text"/>
              <div class="question-mark"
                   data-title="The company name as it should appear on the cards. Maximum 27 characters">?
              </div>
              <div class="invalid-feedback">{{ errors.companyForCard }}</div>
            </div>
          </div>
          <div class="mb-4">
            <div class="form-group has-tooltip">
              <label for="businessAddress1">Registered Address Line 1*</label>
              <Field :class="[{'is-invalid': !!errors.businessAddress1}, 'form-control']"
                     id="businessAddress1" name="businessAddress1" type="text"/>
              <div class="question-mark"
                   data-title="Your full registered company address">?
              </div>
              <div class="invalid-feedback">{{ errors.businessAddress1 }}</div>
            </div>
          </div>
          <div class="mb-4">
            <label for="businessAddress2">Registered Address Line 2</label>
            <Field :class="[{'is-invalid': !!errors.businessAddress2}, 'form-control']"
                   id="businessAddress2" name="businessAddress2" type="text"/>
            <div class="invalid-feedback">{{ errors.businessAddress2 }}</div>
          </div>
          <div class="mb-4">
            <div class="form-group has-tooltip">
              <label for="companyType">Company Type*</label>
              <Field :class="[{'is-invalid': !!errors.companyType}, 'form-select']"
                     id="companyType" name="companyType" as="select">
                <option value="Limited">Limited</option>
                <option value="Partnership">Partnership</option>
                <option value="Sole Trader">Sole Trader</option>
                <option value="Council/Charity">Council/Charity</option>
              </Field>
              <div class="question-mark"
                   data-title="The type of company applying for the card">?
              </div>
              <div class="invalid-feedback">{{ errors.companyType }}</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-4">
            <label for="businessTown">Town / City*</label>
            <Field :class="[{'is-invalid': !!errors.businessTown}, 'form-control']"
                   id="businessTown" name="businessTown" type="text"/>
            <div class="invalid-feedback">{{ errors.businessTown }}</div>
          </div>
          <div class="mb-4">
            <label for="businessCounty">County</label>
            <Field :class="[{'is-invalid': !!errors.businessCounty}, 'form-control']"
                   id="businessCounty" name="businessCounty" type="text"/>
            <div class="invalid-feedback">{{ errors.businessCounty }}</div>
          </div>
          <div class="mb-4">
            <label for="businessPostcode">Postcode*</label>
            <Field :class="[{'is-invalid': !!errors.businessPostcode}, 'form-control']"
                   id="businessPostcode" name="businessPostcode" type="text"/>
            <div class="invalid-feedback">{{ errors.businessPostcode }}</div>
          </div>
          <div class="row">
            <div class="mb-4 col-6">
              <label for="timeinbusinessyears">Time in Business Years*</label>
              <Field :class="[{'is-invalid': !!errors.timeinbusinessyears}, 'form-select']"
                     id="timeinbusinessyears" name="timeinbusinessyears" as="select">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5+">5+</option>
              </Field>
              <div class="invalid-feedback">{{ errors.timeinbusinessyears }}</div>
            </div>
            <div class="mb-4 col-6">
              <label for="timeinbusinessmonths">Time in Business Month*</label>
              <Field :class="[{'is-invalid': !!errors.timeinbusinessmonths}, 'form-select']"
                     id="timeinbusinessmonths" name="timeinbusinessmonths" as="select">
                <option v-for="index in 12" :key="index" :value="(index - 1).toString()">{{
                    index - 1
                  }}
                </option>
              </Field>
              <div class="invalid-feedback">{{ errors.timeinbusinessmonths }}</div>
            </div>
          </div>
        </div>
        <!--if companyType == limited-->
        <div v-if="values.companyType === 'Limited'" class="row">
          <div class="col-md-6">
            <div class="mb-4">
              <label for="companyRegNo">Company Reg Number*</label>
              <Field aria-describedby="companyRegNoHelp"
                     :class="[{'is-invalid': !!errors.companyRegNo}, 'form-control']"
                     id="companyRegNo" name="companyRegNo" type="text"/>
              <div id="companyRegNoHelp" class="form-text">If applicable, please include
                SC, OC or NI in your
                company registration number
              </div>
              <div class="invalid-feedback">{{ errors.companyRegNo }}</div>
            </div>
          </div>
        </div>

        <!--end if companyType == partnership or sole trader  -->
        <div v-if="values.companyType === 'Partnership' || values.companyType === 'Sole Trader'" class="row">
          <div class="col-12">
            <hr class="border-2 border-top border-secondary">
            <h4 class="mb-4">PROPRIETOR / OWNERS</h4>
            <p>For Sole Traders, add the owner's details.</p>
            <p>For Partnerships, add details of all partners.</p>
          </div>
          <div class="text-danger">{{ errors.proprietors }}</div>
          <FieldArray name="proprietors" v-slot="{ fields, push, remove }">
            <!--                  { 'd-none': values[`proprietors`] && values[`proprietors`].length -1 != idx },-->
            <fieldset
                v-for="(field, idx) in fields"
                :class="[ 'border', 'border-1', 'border-primary', 'p-3', 'mb-4', 'rounded-2']"
                :key="field.key"
            >
              <div class="row">
                <div class="col">
                  <legend>Proprietor #{{ idx + 1 }}</legend>
                </div>
                <div class="col">
                  <div class="d-flex justify-content-end">

                    <button
                        class="btn btn-primary" type="button"
                        @click="remove(idx)">Remove
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="mb-4">
                    <label :for="`fullName_${idx}`">Full Name*</label>
                    <Field
                        :class="[{'is-invalid': !!errors[`proprietors[${idx}].fullName`] } , 'form-control']"
                        :id="`fullName_${idx}`"
                        :name="`proprietors[${idx}].fullName`" type="text"/>
                    <div class="invalid-feedback">{{
                        errors[`proprietors[${ idx }].fullName`]
                      }}
                    </div>
                  </div>
                  <h5 class="mb-3">Home Address</h5>

                  <div class="mb-4">
                    <label :for="`address1_${idx}`">Street*</label>
                    <Field
                        :class="[{'is-invalid': !!errors[`proprietors[${idx}].address1`]  }, 'form-control']"
                        :id="`address1_${idx}`" :name="`proprietors[${idx}].address1`"
                        type="text"/>
                    <div class="invalid-feedback">{{
                        errors[`proprietors[${ idx }].address1`]
                      }}
                    </div>
                  </div>

                  <div class="mb-4">
                    <label :for="`address2_${idx}`">Locality</label>
                    <Field
                        :class="[{'is-invalid': !!errors[`proprietors[${idx}].address2`] }, 'form-control']"
                        :id="`address2_${idx}`"
                        :name="`proprietors[${idx}].address2`" type="text"/>
                    <div class="invalid-feedback">{{
                        errors[`proprietors[${ idx }].address2`]
                      }}
                    </div>
                  </div>

                  <div class="mb-4">
                    <label :for="`town_${idx}`">Town*</label>
                    <Field
                        :class="[{'is-invalid': !!errors[`proprietors[${idx}].town`] }, 'form-control']"
                        :id="`town_${idx}`" :name="`proprietors[${idx}].town`"
                        type="text"/>
                    <div class="invalid-feedback">{{
                        errors[`proprietors[${ idx }].town`]
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-4">
                    <label :for="`dob_${idx}`">Date of Birth</label>
                    <Field
                        :name="`proprietors[${idx}].dob`"
                        v-slot="{ field }"
                    >
                      <Datepicker
                          v-bind="field"
                          :name="field.name"
                          :class="[{'is-invalid': !!errors[`proprietors[${idx}].dob`]}, 'form-control']"
                          :id="`dob_${idx}`"
                          :enable-time-picker="false"
                          format="yyyy-MM-dd"
                          model-type="yyyy-MM-dd"
                          :model-value="field.value"
                          :max-date="new Date()"
                      />
                    </Field>
                    <div class="invalid-feedback">{{ errors[`proprietors[${ idx }].dob`] }}</div>
                  </div>
                  <div class="mb-4">
                    <label :for="`county_${idx}`">County</label>
                    <Field
                        :class="[{'is-invalid': !!errors[`proprietors[${idx}].county`] }, 'form-control']"
                        :id="`county_${idx}`" :name="`proprietors[${idx}].county`"
                        type="text"/>
                    <div class="invalid-feedback">{{
                        errors[`proprietors[${ idx }].county`]
                      }}
                    </div>
                  </div>
                  <div class="mb-4">
                    <label :for="`postcode_${idx}`">Postcode*</label>
                    <Field
                        :class="[{'is-invalid': !!errors[`proprietors[${idx}].postcode`] }, 'form-control']"
                        :id="`postcode_${idx}`"
                        :name="`proprietors[${idx}].postcode`" type="text"/>
                    <div class="invalid-feedback">{{
                        errors[`proprietors[${ idx }].postcode`]
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <h5 class="mb-3">Time at Address</h5>
                  <div class="row">
                    <div class="mb-4 col-md-6">
                      <div class="form-group has-tooltip has-tooltip_small">
                        <label :for="`yearsAtAddress_${idx}`">Years*</label>
                        <Field
                            :class="[{'is-invalid': !!errors[`proprietors[${idx}].yearsAtAddress`] }, 'form-select']"
                            :id="`yearsAtAddress_${idx}`"
                            :name="`proprietors[${idx}].yearsAtAddress`"
                            as="select">
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5+">5+</option>
                        </Field>
                        <div class="question-mark"
                             data-title="The length of time in years and months you have lived at the specified address">
                          ?
                        </div>
                        <div class="invalid-feedback">{{
                            errors[`proprietors[${ idx }].yearsAtAddress`]
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="mb-4 col-md-6">
                      <label :for="`monthsAtAddress_${idx}`">Month*</label>
                      <Field
                          :class="[{'is-invalid': !!errors[`proprietors[${idx}].monthsAtAddress`] }, 'form-select']"
                          :id="`monthsAtAddress_${idx}`"
                          :name="`proprietors[${idx}].monthsAtAddress`"
                          as="select">
                        <option v-for="index in 12" :key="index + '_' + idx" :value="(index - 1).toString()">
                          {{ index - 1 }}
                        </option>
                      </Field>
                      <div class="invalid-feedback">{{
                          errors[`proprietors[${ idx }].monthsAtAddress`]
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary " type="button" @click="push({
                                            fullName: '',
                                            dob: '',
                                            address1: '',
                                            county: '',
                                            address2: '',
                                            postcode: '',
                                            town: '',
                                            yearsAtAddress: '',
                                            monthsAtAddress: '' })">
                <span v-if="values[`proprietors`] && !!values[`proprietors`].length">Add another proprietor</span>
                <span v-else>Add proprietor</span>
              </button>
            </div>
          </FieldArray>
        </div>


      </FormStep>

      <FormStep>
        <div class="col-12">
          <h5 class="text-center">Average Monthly Spend</h5>
        </div>
        <div class="col-md-6">
          <div class="mb-4">
            <div class="form-group has-tooltip">
              <label for="monthSpendDiesel">Diesel</label>
              <Field aria-describedby="monthSpendDieselHelp"
                     :class="[{'is-invalid': !!errors.monthSpendDiesel}, 'form-control']"
                     id="monthSpendDiesel" name="monthSpendDiesel" type="number"/>
              <div class="question-mark"
                   data-title="The amount you spend per month on diesel fuel">?
              </div>
              <div class="invalid-feedback">{{ errors.monthSpendDiesel }}</div>
              <div id="monthSpendDieselHelp" class="form-text">Value in £*</div>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="isPetrolRequired">
          <div class="mb-4">
            <div class="form-group has-tooltip">
              <label for="monthSpendPetrol">Petrol</label>
              <Field aria-describedby="monthSpendPetrolHelp"
                     :class="[{'is-invalid': !!errors.monthSpendPetrol}, 'form-control']"
                     id="monthSpendPetrol" name="monthSpendPetrol" type="number"/>
              <div class="question-mark"
                   data-title="The amount you spend per month on petrol fuel">?
              </div>
              <div class="invalid-feedback">{{ errors.monthSpendPetrol }}</div>
              <div id="monthSpendPetrolHelp" class="form-text">Value in £*</div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <h5 class="text-center">Number of Vehicles</h5>
        </div>
        <div class="col-md-6">
          <div class="mb-4">
            <div class="form-group has-tooltip">
              <label for="hGVDiesel">Diesel HGV</label>
              <Field :class="[{'is-invalid': !!errors.hGVDiesel}, 'form-control']" id="hGVDiesel"
                     name="hGVDiesel" type="number"/>
              <div class="question-mark"
                   data-title="The total number of HGVs you run in your business">?
              </div>
              <div class="invalid-feedback">{{ errors.hGVDiesel }}</div>
            </div>
          </div>

          <div class="mb-4">
            <div class="form-group has-tooltip">
              <label for="vanLCVDiesel">Diesel Vans\LCV</label>
              <Field :class="[{'is-invalid': !!errors.vanLCVDiesel}, 'form-control']"
                     id="vanLCVDiesel" name="vanLCVDiesel" type="number"/>
              <div class="question-mark"
                   data-title="The total number of diesel vans you run in your business">?
              </div>
              <div class="invalid-feedback">{{ errors.vanLCVDiesel }}</div>
            </div>
          </div>

          <div class="mb-4">
            <div class="form-group has-tooltip">
              <label for="carDiesel">Diesel Cars</label>
              <Field :class="[{'is-invalid': !!errors.carDiesel}, 'form-control']" id="carDiesel"
                     name="carDiesel" type="number"/>
              <div class="question-mark"
                   data-title="The total number of diesel cars you run in your business">?
              </div>
              <div class="invalid-feedback">{{ errors.carDiesel }}</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="my-4 py-3">
          </div>

          <div class="mb-4">
            <div class="form-group has-tooltip">
              <label for="vanLCVPetrol">Petrol Vans\LCV</label>
              <Field :class="[{'is-invalid': !!errors.vanLCVPetrol}, 'form-control']"
                     id="vanLCVPetrol" name="vanLCVPetrol" type="number"/>
              <div class="question-mark"
                   data-title="The total number of petrol vans you run in your business">?
              </div>
              <div class="invalid-feedback">{{ errors.vanLCVPetrol }}</div>
            </div>
          </div>

          <div class="mb-4">
            <div class="form-group has-tooltip">
              <label for="carPetrol">Petrol Cars</label>
              <Field :class="[{'is-invalid': !!errors.carPetrol}, 'form-control']" id="carPetrol"
                     name="carPetrol" type="number"/>
              <div class="question-mark"
                   data-title="The total number of petrol cars you run in your business">?
              </div>
              <div class="invalid-feedback">{{ errors.carPetrol }}</div>
            </div>
          </div>
        </div>

        <hr class="border-2 border-top border-secondary">
        <div class="row">
          <div class="col-12">
            <h5 class="mb-3">Card Details</h5>
            <p>You MUST enter the driver's name and/or registration number for each card you
              require. Create your
              cards using the form below, clicking 'Save Card' to save the details.</p>
          </div>
          <div class="col-12">
            <div class="text-danger">{{ errors.vehicles }}</div>
            <FieldArray name="cards" v-slot="{ fields, push, remove }">
              <fieldset
                  v-for="(field, idx) in fields"
                  :class="['border', 'border-1', 'border-primary', 'p-3', 'mb-4', 'rounded-2']"
                  :key="field.key"
              >
                <div class="row">
                  <div class="col">
                    <legend>Card #{{ idx + 1 }}</legend>
                  </div>
                  <div class="col">
                    <div class="d-flex justify-content-end">
                      <button v-if="values[`vehicles`] && values[`vehicles`].length !== 1 "
                              class="btn btn-primary"
                              type="button" @click="remove(idx)">Remove
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <div class="mb-4">
                      <label :for="`driverName_${idx}`">Driver Name</label>
                      <Field :class="[{'is-invalid': !!errors[`vehicles[${idx}].driverName`] }, 'form-control']"
                             :id="`driverName_${idx}`"
                             :name="`vehicles[${idx}].driverName`"
                             type="text"/>
                      <div class="invalid-feedback">{{
                          errors[`vehicles[${ idx }].driverName`]
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="col-2 text-center">
                    <p class="mt-4 text-nowrap">AND/OR</p>
                  </div>
                  <div class="col-md-5">
                    <div class="mb-4">
                      <label :for="`registrationNo_${idx}`">Registration No</label>
                      <Field :class="[{'is-invalid': !!errors[`vehicles[${idx}].registrationNo`]}, 'form-control']"
                             :id="`registrationNo_${idx}`"
                             :name="`vehicles[${idx}].registrationNo`"
                             type="text"/>
                      <div class="invalid-feedback">{{
                          errors[`vehicles[${ idx }].registrationNo`]
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-4">
                      <label :for="`purchaseOptionID_${idx}`">Purchase Options</label>
                      <Field :class="[{'is-invalid': !!errors[`vehicles[${idx}].purchaseOptionID`]}, 'form-select']"
                             :id="`purchaseOptionID_${idx}`"
                             :name="`vehicles[${idx}].purchaseOptionID`"
                             as="select">
                        <option
                            v-for="purchaseOption in (isPurchaseDieselOnly ? card.purchase_options.filter(item => item.name === 'Diesel only') : card.purchase_options)"
                            :key="purchaseOption.id"
                            :value="purchaseOption.id">{{ purchaseOption.name }}
                        </option>
                      </Field>
                      <div class="invalid-feedback">{{
                          errors[`vehicles[${ idx }].purchaseOptionID`]
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div class="d-flex justify-content-end">
                <button class="btn btn-primary " type="button"
                        @click="push({ driverName: '', purchaseOptionID: '', registrationNo: '' })">
                  <span v-if="values[`vehicles`] && !!values[`vehicles`].length">Add another card</span>
                  <span v-else>Add card</span>
                </button>
              </div>
            </FieldArray>
          </div>
          <div class="col-md-6" v-if="isSpecialRequired">
            <div class="mb-4">
              <label for="special">{{
                  card.id === CARDS.TRADE_POINT ? 'Tradepoint Memebership number' : 'Special Requirements'
                }}</label>
              <Field :class="[{'is-invalid': !!errors.special}, 'form-control']" id="special"
                     name="special" type="number"/>
              <div class="invalid-feedback">{{ errors.special }}</div>
            </div>
          </div>
        </div>

      </FormStep>

      <FormStep>

        <div class="col-12" v-if="isOnlineAccountTextRequired">
          <h5 class="mb-3">Online Account</h5>
          <p>We will create an online account for you to manage your fuel card. To help us reset
            your account if you forget your login, choose a security question from the list
            below and type your answer beside it. A username and password will be sent to you if
            the application is approved.</p>
        </div>
        <div class="col-md-6" v-if="isQARequired">
          <div class="mb-4">
            <label for="question">Security Question*</label>
            <Field v-if="questions.data" :class="[{'is-invalid': !!errors.question}, 'form-select']"
                   id="question" name="question" as="select">
              <option v-for="question in questions.data" :key="question.id" :value="question.id">{{
                  question.name
                }}
              </option>
            </Field>
            <div class="invalid-feedback">{{ errors.question }}</div>
          </div>
        </div>
        <div class="col-md-6" v-if="isQARequired">
          <div class="mb-4">
            <label for="answer">Answer*</label>
            <Field :class="[{'is-invalid': !!errors.answer}, 'form-control']"
                   id="answer" name="answer" type="text"/>
            <div class="invalid-feedback">{{ errors.answer }}</div>
          </div>
        </div>
        <div class="col-12">
          <hr class="border-2 border-top border-secondary">
          <h5 class="mb-3">Credit Checking*</h5>
          <div class="mb-4 form-check">
            <Field :class="[{'is-invalid': !!errors.creditCheck}, 'form-check-input']"
                   id="creditCheck" name="creditCheck" type="checkbox" :value="true"
                   :unchecked-value="false">
            </Field>
            <label class="form-check-label" for="creditCheck">The information which you provide will be disclosed to a
              licensed credit reference agency which will retain a record of the search. It will be used by us to assess
              your applications and for debt tracing and fraud prevention.</label>
            <div class="invalid-feedback">{{ errors.creditCheck }}</div>
          </div>

          <div v-if="isZeroLiabilityRequired">
            <hr class="border-2 border-top border-secondary">
            <h5 class="mb-3">Zero Liability Protection</h5>
            <div class="mb-4 form-check">
              <Field :class="[{'is-invalid': !!errors.zero_liability}, 'form-check-input']"
                     id="zero_liability" name="zero_liability" type="checkbox" :value="true"
                     :unchecked-value="false">
              </Field>
              <label class="form-check-label" for="zero_liability">Tick this box for Zero Liability
                Protection on your fuel cards. Protect your business in the event of card loss
                or theft. <a class="popup-btn" v-on:click.prevent="setShowZeroLiabilityModal(true)">Zero
                  Liability Protection Terms and Conditions</a></label>
              <div class="invalid-feedback">{{ errors.zero_liability }}</div>
              <Modal v-if="showZeroLiabilityModal" :title="`Zero Liability Protection`"
                     @close="setShowZeroLiabilityModal(false)">
                <p>
                  Zero Liability Protection is available on your fuel cards. In the unfortunate event
                  that your fuel cards are lost or stolen, you will not be liable for any fraudulent
                  transactions on your account from the moment you inform us.
                </p>
                <p>Zero Liability offers you peace of mind for just Â£1 per card per month!!</p>
                <p>Please see below Terms and Conditions for our Zero Liability Protection</p>
                <hr width="100%">
                <p>
                  <strong>
                    The Fuelcard Company Terms and Conditions<br>
                    Addendum for ZERO LIABILITY
                  </strong>
                </p>
                <p>
                  The following Terms and Conditions apply in relation to the Zero Liability option
                  you have purchased.
                </p>
                <p><strong>Looking after your Card</strong></p>
                <p>
                  <strong>(i)</strong> The customer agrees that it will take care to keep all Cards
                  issued to it safe and to prevent them from being lost, stolen or used by any person
                  (other than an Authorised User), and will, without prejudice to the generality of
                  the foregoing: -
                </p>
                <p>
                  a) sign or write the vehicle registration on the back of each Card immediately upon
                  receipt;
                </p>
                <p>b) not allow anyone other than an Authorised User to use the Card; and</p>
                <p>c) not disclose the PIN to any person other than an Authorised User of that Card.</p>
                <p>
                  <strong>(ii)</strong> Security of the PIN is paramount and, therefore, the Customer
                  agrees that it will (and will procure that any Authorised User will);-
                </p>
                <p>
                  a) not write (or otherwise record) the PIN on the Card or any other item normally
                  kept with or near the Card;
                </p>
                <p>
                  b) not write the PIN (or otherwise record) in a way that can be easily understood
                  by someone else;
                </p>
                <p>c) not disclose the PIN to someone else, including a representative of the Company;</p>
                <p>d) destroy the PIN advice immediately after receipt;</p>
                <p>e) not interfere with any magnetic strip or chip in the Card; and</p>
                <p>
                  f) comply with all reasonable instructions the Company issues regarding keeping
                  the PIN safe.
                </p>
                <p>
                  <strong>(iii)</strong> If any card is lost or stolen or if the Customer thinks it
                  may be misused or suspects that someone else knows a PIN, the customer
                </p>
                <p>
                  must immediately notify the company. The Customer can notify the company by telephone
                  on 0845 456 1400 (or such other number as the Company may, from time to time, notify
                  to the Customer), but this must be confirmed in writing, the cards can be placed
                  on stop via e-flex our online account management portal; if the Customer does not
                  use e-flex the company requires written confirmation via email or fax.
                </p>
                <p>
                  <strong>(iv)</strong> The Customer agrees that it will provide the Company with
                  any help the Company believes is necessary to try to recover any Card reported lost
                  or stolen in accordance with Clause (iii) above or to find out who might have used
                  it or the PIN. In all cases including but not limited to when fraudulent drawings
                  have occurred, the Customer will be required to report the matter to the police,
                  obtain a police crime reference and co-operate with the police and/or the Company
                  in efforts to recover the card and/or in any associated civil and/or criminal proceedings.
                </p>
                <p>
                  <strong>(v)</strong> The Customer agrees that the Company may give the police or
                  any prosecuting authority and/or the Companyâ€™s solicitors any information
                  they need if the Company believes it will help them, or that it will avoid or mitigate
                  loss or help recover the card and/or any missing fuel, lubricant, gas oil or other
                  goods and services (or the proceeds thereof).
                </p>
                <p>
                  <strong>(vi)</strong> It the Customer finds the card after it has been reported
                  missing under Clause iii, the Customer agrees not to use it but to cut it in half
                  and return it immediately to the Company by registered post. If the Customer does
                  use the Card to draw Fuel or purchase the lubricants or gas oil or other goods and
                  services the Company shall be entitled to take the amount of the withdrawal out
                  of the Customerâ€™s account
                </p>
                <p><strong>Responsibility for Loss</strong></p>
                <p>
                  <strong>(vii)</strong> If the Customer does not receive the Card from the Company
                  (for example, if it is lost in the post), and it has notified the Company of such
                  non receipt, in accordance with Clause (iii), the Company agrees that it will credit
                  the value of any fuel or lubricants or gas oil or other goods and services taken
                  from the Customerâ€™s account if it is proven that someone else has misused
                  the Card.
                </p>
                <p>
                  <strong>(viii)</strong> Until such time as the Customer informs the Company via
                  Eflex or in writing, under clause (iii) that the Card has been lost or stolen, or
                  may be misused, or that someone else knows the PIN, the Customer will be responsible
                  for all drawings of Fuel and purchases of lubricants or gas oil or other goods and
                  services made using the Card. (ix) The Customer will not be responsible for any
                  transactions made using a Card issued to it after the Customer has reported it lost
                  or stolen under clause (iii), unless the Card is used by the Customer or any Authorised
                  User on their behalf or if the Customer or any Authorised User has been fraudulent
                  or negligent. The Company agrees that it will credit the Customerâ€™s account
                  following the completion of our internal investigation and any external enquiry
                  covered under clauses (iv) and (v) (x) The Company will not be responsible and will
                  accept no liability for any loss or expense suffered by the Customer if: (a) a card
                  is damaged; or (b) any person or machine refuses to accept a Card (xi) If the Customer
                  requests the Company to deactivate a Card for any reason other than loss or theft,
                  the Customer will remain responsible for transactions on the Card until such time
                  as the Card is cut in half and returned to (and received by) the Company by registered
                  post. Card Management
                </p>
                <p>
                  <strong>(xii)</strong> Cards shall at all times remain the property of the Company
                  and shall be returned to the Company on demand;
                </p>
                <p>
                  <strong>(xiii)</strong> The Customer shall forthwith notify the Company if at any
                  time the Customer suspends cancels or otherwise prohibits use of any Card for any
                  reason and if such notification is given orally it must as soon as is reasonably
                  orally it must as soon as is reasonably practicable be confirmed in writing. Such
                  notification shall include details of the Authorised Userâ€™s name and Card
                  number. In the event that a Card is cancelled and/or withdrawn from use the Customer
                  will in addition to the above confirm in writing that the Card in question has been
                  physically destroyed. Failure to confirm such destruction will prevent the cancellation
                  and the Card in question will remain on stop until the expiry date thereof has been
                  reached. The Customer shall indemnify and keep indemnified the Company its estate
                  and effects against all damages costs expenses and liability of whatsoever nature
                  which the Company shall suffer by reason of the subsequent use of a Card that the
                  Customer had confirmed as being destroyed.
                </p>
              </Modal>
            </div>
          </div>

          <hr class="border-2 border-top border-secondary">
          <h5 class="mb-3">Data Protection</h5>
          <p>You have the right to access , change or delete the data you have supplied us. In
            order to do so you should contact <a href="mailto:GDPR@fleetcor.com">GDPR@fleetcor.com</a>
          </p>
          <hr class="border-2 border-top border-secondary">
          <h5 class="mb-3">Authority to Apply*</h5>
          <div class="mb-4 form-check">
            <Field :class="[{'is-invalid': !!errors.authorityToApply}, 'form-check-input']"
                   id="authorityToApply" name="authorityToApply" type="checkbox" :value="true"
                   :unchecked-value="false">
            </Field>
            <label class="form-check-label" for="authorityToApply">I confirm that I am
              authorised to apply for fuelcards on behalf of the company whose details I have
              entered on this form. The partners/proprietors detailed within the application
              under step two have given their informed consent for us and our associated
              companies (link to privacy policy) to make credit reference searches, maintain
              records of the searches and for their details to be processed in accordance with
              this applications</label>
            <div class="invalid-feedback">{{ errors.authorityToApply }}</div>
          </div>

          <hr class="border-2 border-top border-secondary">
          <h5 class="mb-3">Terms & Conditions*</h5>
          <div class="mb-4 form-check" v-if="isTermsConditionsFieldRequired">
            <Field :class="[{'is-invalid': !!errors.termsConditions}, 'form-check-input']"
                   id="termsConditions" name="termsConditions" type="checkbox"
                   :value="true" :unchecked-value="false">
            </Field>
            <label class="form-check-label" for="termsConditions">I confirm that I have read
              and agree to the <a v-if="termsConditions" class="popup-btn"
                                  v-on:click.prevent="setShowTermsConditionsModal(true)">Terms and Conditions</a><a
                  v-else :href="termsConditionsLink" target="_blank">Terms and Conditions</a>.
            </label>
            <div class="invalid-feedback">{{ errors.termsConditions }}</div>
            <Modal v-if="showTermsConditionsModal" @close="setShowTermsConditionsModal(false)">
              <div v-html="termsConditions"></div>
            </Modal>
          </div>

          <div class="mb-4 form-check">
            <Field :class="[{'is-invalid': !!errors.thirdParty}, 'form-check-input']" id="thirdParty"
                   name="thirdParty" type="checkbox" :value="true" :unchecked-value="false">
            </Field>
            <label class="form-check-label" for="thirdParty">Tick this box if you consent to us
              sharing your information with other carefully selected companies who may contact
              you in relation to other products and services. </label>
            <div class="invalid-feedback">{{ errors.thirdParty }}</div>
          </div>

        </div>

      </FormStep>
    </FormWizard>
  </div>
</template>

<script>
import { Field, FieldArray } from 'vee-validate'
import * as yup from 'yup'
import FormWizard from '@/components/FormWizard.vue'
import FormStep from '@/components/FormStep.vue'
import Datepicker from '@vuepic/vue-datepicker'
import { ref } from 'vue'
import axios from 'axios'
import {
  checkIsOnlineAccountTextRequired,
  checkIsPetrolRequired,
  checkIsPurchaseDieselOnly,
  checkIsQARequired,
  checkIsRequiredTermsConditionsPopup,
  checkIsSpecialRequired, checkIsTermsConditionsFieldRequired,
  checkIsZeroLiabilityRequired,
  getPrivacyLink, getTermsConditionsLink
} from '@/utils/helpers/cards'
import Modal from '@/components/Modal.vue'
import { CARDS } from '@/utils/enums/card-enums'

export default {
  name: 'RegularForm',
  computed: {
    CARDS () {
      return CARDS
    }
  },
  components: {
    Modal,
    FormWizard,
    FormStep,
    FieldArray,
    Field,
    Datepicker
  },
  emits: ['submit'],
  props: {
    card: {
      type: Object,
      required: true,
    },
    salesPeople: {
      type: Object,
      required: true,
    }
  },
  setup (props, { emit }) {
    const privacyLink = getPrivacyLink(props.card.id)
    const termsConditionsLink = getTermsConditionsLink(props.card.id)
    const isPetrolRequired = checkIsPetrolRequired(props.card.id)
    const isPurchaseDieselOnly = checkIsPurchaseDieselOnly(props.card.id)
    const isSpecialRequired = checkIsSpecialRequired(props.card.id)
    const isQARequired = checkIsQARequired(props.card.id)
    const isZeroLiabilityRequired = checkIsZeroLiabilityRequired(props.card.id)
    const isTermsConditionsFieldRequired = checkIsTermsConditionsFieldRequired(props.card.id)
    const isOnlineAccountTextRequired = checkIsOnlineAccountTextRequired(props.card.id)

    const showZeroLiabilityModal = ref(false)
    const setShowZeroLiabilityModal = (val) => {
      showZeroLiabilityModal.value = val
    }

    const showTermsConditionsModal = ref(false)
    const setShowTermsConditionsModal = (val) => {
      showTermsConditionsModal.value = val
    }

    // break down the validation steps into multiple schemas
    const validationSchema = [
      yup.object().shape({

        salesperson: yup.string().required().label('Salesperson'),
        source: yup.string().required().label('Source'),

        promofreecards: yup.boolean().label('Promo Free Cards'),
        promo1pplreduction: yup.boolean().label('Promo 1ppl Discount'),
        promo2pplreduction: yup.boolean().label('Promo 2ppl Discount'),
        promoreferafriend: yup.boolean().label('Promo Refer a Friend'),
        promofreefuel60: yup.boolean().label('Promo Free Fuel 60'),
        // step 2
        companyFull: yup.string().required().label('Registered Company Name').max(27, 'The company full may not be greater than 27 characters.'),
        title: yup.string().required().label('Title'),
        firstName: yup.string().required().label('First Name'),
        lastName: yup.string().required().label('Last Name'),
        jobTitle: yup.string().required().label('Job title'),
        telephone: yup.string().required().label('Telephone'),
        mobile: yup.string().label('Moblie'),
        email: yup.string().required().email().label('Email'),
      }), yup.object().shape({
        // step 3

        companyForCard: yup.string().required().label('Company Name to appear on cards'),
        businessAddress1: yup.string().required().label('Registered Address Line 1'),
        businessAddress2: yup.string().label('Registered Address Line 2'),
        companyType: yup.string().required().label('Company Type'),

        //companyType == Limited
        companyRegNo: yup.string().label('Company Type').when(
            'companyType', {
              is: 'Limited',
              then: yup.string().required()
            }
        ),

        proprietors: yup.array().of(
            yup.object().shape({
              fullName: yup.string().required().label('Full Name'),
              dob: yup.string().required().label('Date of Birth'),
              address1: yup.string().required().label('Street '),
              address2: yup.string().label('Locality'),
              county: yup.string().label('County'),
              postcode: yup.string().required().label('Postcode').max(8, 'Postcode must be less than 8 characters'),
              town: yup.string().required().label('Town'),
              yearsAtAddress: yup.string().required().label('Years'),
              monthsAtAddress: yup.string().required().label('Months')
            })
        ).when('companyType', {
          is: (companyType) => {
            return (companyType === 'Partnership' || companyType === 'Sole Trader')
          },
          then: yup.array().min(1, 'Add at least one proprietor').required('Add at least one proprietor')
        }),
        //companyType == Partnership or companyType == Sole trader

        businessTown: yup.string().required().label('Town / City'),
        businessCounty: yup.string().label('County'),
        businessPostcode: yup.string().required().label('Postcode').max(8, 'Business postcode must be less than 8 characters'),

        timeinbusinessyears: yup.string().required().label('Years'),
        timeinbusinessmonths: yup.string().required().label('Month'),
      }), yup.object().shape({

        // step 4
        monthSpendDiesel: yup.number().label('Average Monthly Spend Diesel'),
        hGVDiesel: yup.number().label('Number of Vehicles HGV Diesel'),
        vanLCVDiesel: yup.number().label('Number of Vehicles Vans\\LCV Diesel'),
        carDiesel: yup.number().label('Number of Vehicles Cars Diesel'),

        monthSpendPetrol: yup.number().label('Average Monthly Spend Petrol'),
        vanLCVPetrol: yup.number().label('Number of Vehicles Vans\\LCV Petrol'),
        carPetrol: yup.number().label('Number of Vehicles Cars Petrol'),

        // card details
        vehicles: yup.array().of(
            yup.object().shape({
              driverName: yup.string().when('registrationNo', {
                is: (registrationNo) => !registrationNo || registrationNo.length === 0,
                then: yup.string().required('At least one of the fields is required'),
              }).label('Driver Name'),

              registrationNo: yup.string().when('driverName', {
                is: (driverName) => !driverName || driverName.length === 0,
                then: yup.string().required('At least one of the fields is required'),
              }).label('Registration No'),

              purchaseOptionID: yup.number().label('Purchase Options'),
            }, ['driverName', 'registrationNo'])
        ).strict().min(1, 'Add at least one card').required('Add at least one card'),

        special: yup.number().label('Special requirements'),
      }), yup.object().shape({
        //  step 4
        question: yup.string().when('isQARequired', {
          is: () => isQARequired,
          then: yup.string().required()
        }).label('Security Question'),
        answer: yup.string().when('isQARequired', {
          is: () => isQARequired,
          then: yup.string().required()
        }).label('Answer '),
        creditCheck: yup.boolean().required().oneOf([true], 'Credit Checking is required field').label('Credit Checking '),
        zero_liability: yup.string().label('Zero Liability Protection'),
        authorityToApply: yup.boolean().required().oneOf([true], 'Authority to Apply is required field').label('Authority to Apply'),
        termsConditions: yup.boolean().when('isTermsConditionsFieldRequired', {
          is: () => isTermsConditionsFieldRequired,
          then: yup.boolean().required().oneOf([true], 'Terms & Conditions is required field').label('Terms & Conditions')
        }),
        thirdParty: yup.boolean().label('Consent'),
      })
    ]

    /**
     * Only Called when the last step is submitted
     */
    function onSubmit (formData) {
      emit('submit', formData)
    }

    const BASE_URL = process.env.VUE_APP_API_ENDPOINT
    const questions = ref([])
    const termsConditions = ref('')

    function onNext (formData) {
      if (formData.stage === 2) {
        formData.timeInBusiness = `${ formData.timeinbusinessyears }|${ formData.timeinbusinessmonths }`
        delete formData.timeinbusinessyears
        delete formData.timeinbusinessmonths
      }

      if (formData.stage === 3) {
        formData.moreThan = false
        if (isQARequired) {
          axios.get(`${ BASE_URL }api/questions`, {
            headers: {
              'X-Authorization': process.env.VUE_APP_FLEETCOR_API_KEY,
            }
          }).then(res => {
            if (res?.data) {
              questions.value = res.data
            }
          })
        }

        if (checkIsRequiredTermsConditionsPopup(props.card.id)) {
          axios.get(`${ BASE_URL }api/terms-conditions?cardID=${ props.card.id }`, {
            headers: {
              'X-Authorization': process.env.VUE_APP_FLEETCOR_API_KEY,
            }
          }).then(res => {
            if (res?.data) {
              termsConditions.value = res.data.data.content
            }
          })
        }
      }
    }

    const initialData = [
      {}, {}, {}, {}, {}
    ]
    const stepNames = [
      {
        name: 'ABOUT YOU'
      },
      {
        name: 'YOUR COMPANY DETAILS'
      },
      {
        name: 'PERSONALISE YOUR CARD'
      },
      {
        name: 'FINISH'
      },
    ]

    return {
      stepNames,
      initialData,
      validationSchema,
      questions,
      privacyLink,
      isPetrolRequired,
      isPurchaseDieselOnly,
      isSpecialRequired,
      isQARequired,
      isZeroLiabilityRequired,
      showZeroLiabilityModal,
      showTermsConditionsModal,
      termsConditions,
      setShowTermsConditionsModal,
      setShowZeroLiabilityModal,
      onSubmit,
      onNext,
      termsConditionsLink,
      isTermsConditionsFieldRequired,
      isOnlineAccountTextRequired
    }
  },
}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.popup-btn {
  cursor: pointer;
}
</style>