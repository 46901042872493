<template>
  <div class="container pt-4">
    <div class="row">
      <div class="col-12">
        <FleetcorHub msg="Application Form"/>
      </div>
    </div>
  </div>
</template>
<script>
import FleetcorHub from '@/components/FleetcorHub.vue';
// import './assets/sass/app.scss'

export default {
  name: 'App',
  components: {
    FleetcorHub
  }
}
</script>