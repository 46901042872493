<template>
  <form class="mb-5" @submit="onSubmit">
    <ul class="progress-bar mt-5 flex-row bg-white text-dark text-wrap p-0">
      <li v-for="(item, index) in stepNames"
          :class="[{ 'active': currentStepIdx >= index }, 'd-block p-md-4 position-relative']" :key="index">{{
          item.name
        }}
      </li>
    </ul>
    <slot v-bind:errors="errors" v-bind:formData="formData" v-bind:values="values"/>

    <div class="d-flex justify-content-end">
      <button class="btn btn-secondary m-3 px-5" v-if="hasPrevious" type="button" @click="goToPrev">
        Previous
      </button>
      <button class="btn btn-primary m-3 px-5 me-0" type="submit">{{ isLastStep ? 'Submit' : 'Next' }}</button>
    </div>
  </form>
</template>

<script>
import { useForm } from 'vee-validate'
import { ref, computed, provide } from 'vue'
import axios from 'axios'

export default {
  name: 'FormWizard',
  emits: ['next', 'submit'],
  props: {
    card: {
      type: Object,
      required: true,
    },
    validationSchema: {
      type: Array,
      required: true,
    },
    initialData: {
      type: Array,
      required: true,
    },
    validationErrors: {
      type: Object,
      required: false,
    },
    stepNames: {
      type: Array,
      required: true,
    }
  },
  setup (props, { emit }) {
    const formData = ref(Object.assign({}, ...props.initialData))
    const serverData = ref({})
    const currentStepIdx = ref(0)
    const BASE_URL = process.env.VUE_APP_API_ENDPOINT

    // Injects the starting step, child <form-steps> will use this to generate their ids
    const stepCounter = ref(0)
    provide('STEP_COUNTER', stepCounter)
    // Inject the live ref of the current index to child components
    // will be used to toggle each form-step visibility
    provide('CURRENT_STEP_INDEX', currentStepIdx)

    // current step name
    const formStepName = computed(() => {
      return props.stepNames[currentStepIdx.value]
    })

    provide('CURRENT_STEP_NAME', formStepName)

    // if this is the last step
    const isLastStep = computed(() => {
      return currentStepIdx.value === stepCounter.value - 1
    })

    // If the `previous` button should appear
    const hasPrevious = computed(() => {
      return currentStepIdx.value > 0
    })

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return props.validationSchema[currentStepIdx.value]
    })

    // extracts the individual step initial data
    const currentInitialData = computed(() => {
      return props.initialData[currentStepIdx.value]
    })

    // vee-validate will be aware of computed schema changes
    const { errors, values, resetForm, handleSubmit } = useForm({
      validationSchema: currentSchema,
      initialValues: currentInitialData,
    })

    function onInvalidSubmit () {
      const el = document.querySelector('.is-invalid:first-of-type')
      el.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }

    // We are using the "submit" handler to progress to next steps
    // and to submit the form if its the last step
    // parent can opt to listen to either events if interested
    const onSubmit = handleSubmit((values) => {
      let currentValues = {}
      Object.keys(currentSchema.value.fields).forEach(function(key) {
        if (!(key in values) && currentSchema.value.fields[key].constructor.name === 'ArraySchema') {
          currentValues[key] = []
        } else if (key in values) {
          currentValues[key] = values[key]
        }
      })

      formData.value = {
        ...formData.value,
        ...currentValues,
      }

      // Sets initial values for the values already filled
      // effectively fills the inputs when clicking on "previous"
      resetForm({
        values: {
          ...formData.value,
        },
      })

      const data = {
        ...currentValues,
        appGUID: serverData.value?.AppGUID,
        cardID: props.card['id'],
        skinName: 'tfc2',
        stage: currentStepIdx.value + 1
      }

      const url = props.card['form_type'] === 'Visa'
          ? `${ BASE_URL }api/applications/allstar/plus`
          : `${ BASE_URL }api/applications`

      if (!isLastStep.value) {
        emit('next', data)

        axios.post(url, data, {
          headers: {
            'X-Authorization': process.env.VUE_APP_FLEETCOR_API_KEY,
          }
        }).then(response => {
          serverData.value = response.data
          currentStepIdx.value++
        }).catch(err => {
          console.log('custom err: ' + err)
        })

        return
      }

      emit('submit', { data, url })

    }, onInvalidSubmit)

    function goToPrev () {
      if (currentStepIdx.value === 0) {
        return
      }
      let currentValues = {}

      Object.keys(currentSchema.value.fields).forEach(function(key) {
        if (!(key in values) && currentSchema.value.fields[key].constructor.name === 'ArraySchema') {
          currentValues[key] = []
        } else if (key in values) {
          currentValues[key] = values[key]
        }
      })

      formData.value = {
        ...formData.value,
        ...currentValues,
      }

      currentStepIdx.value--

      resetForm({
        values: {
          ...formData.value,
        },
      })
    }

    return {
      currentStepIdx,
      formData,
      values,
      errors,
      onSubmit,
      hasPrevious,
      isLastStep,
      goToPrev,
    }
  },
}
</script>
