const CARDS = Object.freeze({
  RHA: 79,
  RHA_DIESEL: 42,
  DIESEL_ADVANCE: 28,
  SHELL_COMMERCIAL: 77,
  ATS_GOLD: 31,
  ATS_SILVER: 34,
  FTA: 80,
  KEYFUELS: 11,
  TEXACO: 10,
  SHELL_MULTI: 15,
  SHELL_CRT: 3,
  ESSO_COMMERCIAL: 9,
  ESSO_MULTI: 8,
  ESSO_SINGLE: 7,
  ALLSTAR_SUPERMARKET: 40,
  ALLSTAR_NO_OFFER: 37,
  TRADE_POINT: 32,
  ARVAL: 36,
  AA_FLEET: 43,
  ALLSTAR_FUEL_CARD: 29,
  ALLSTAR_ONE: 51,
  ALLSTAR_MONITOR: 46,
  ALLSTAR_EXPENSE: 76,
  ALLSTAR_SUPPLIER_PAY: 101
})

const cardsToHide = [
  CARDS.TRADE_POINT,
  CARDS.AA_FLEET,
  CARDS.KEYFUELS,
  CARDS.TEXACO,
  CARDS.SHELL_MULTI,
  CARDS.SHELL_CRT,
  CARDS.ALLSTAR_ONE,
  CARDS.ESSO_COMMERCIAL,
  CARDS.ESSO_MULTI,
  CARDS.ESSO_SINGLE,
  CARDS.ALLSTAR_SUPERMARKET,
  CARDS.SHELL_COMMERCIAL,
  CARDS.ALLSTAR_FUEL_CARD
]

const fuelCardTermsConditions = [
  CARDS.KEYFUELS,
  CARDS.TEXACO,
  CARDS.SHELL_MULTI,
  CARDS.SHELL_CRT,
  CARDS.ESSO_COMMERCIAL,
  CARDS.ESSO_MULTI,
  CARDS.ESSO_SINGLE,
  CARDS.SHELL_COMMERCIAL,
  CARDS.DIESEL_ADVANCE
]

const allstarTermsConditions = [
  CARDS.ALLSTAR_FUEL_CARD,
  CARDS.ALLSTAR_ONE,
  CARDS.ALLSTAR_NO_OFFER,
  CARDS.ALLSTAR_SUPERMARKET
]

const dieselPrivacyPolicy = [
  CARDS.RHA,
  CARDS.RHA_DIESEL,
  CARDS.DIESEL_ADVANCE
]

const allstarPrivacyPolicy = [
  CARDS.ATS_GOLD,
  CARDS.TRADE_POINT,
  CARDS.ATS_SILVER,
  CARDS.ARVAL,
  CARDS.AA_FLEET,
  CARDS.ALLSTAR_FUEL_CARD,
  CARDS.ALLSTAR_ONE,
  CARDS.ALLSTAR_MONITOR,
  CARDS.ALLSTAR_NO_OFFER,
  CARDS.ALLSTAR_SUPERMARKET
]

const fuelCardPrivacyPolicy = [
  CARDS.FTA,
  CARDS.TEXACO,
  CARDS.SHELL_MULTI,
  CARDS.SHELL_CRT,
  CARDS.ESSO_COMMERCIAL,
  CARDS.ESSO_MULTI,
  CARDS.ESSO_SINGLE,
  CARDS.SHELL_COMMERCIAL
]

const keyfuelsPrivacyPolicy = [
  CARDS.KEYFUELS
]

const notRequiredPetrol = [
  CARDS.DIESEL_ADVANCE,
  CARDS.SHELL_COMMERCIAL
]

const notRequiredTermsConditionsField = [
  CARDS.ALLSTAR_MONITOR
]

const purchaseDieselOnly = [
  CARDS.RHA,
  CARDS.DIESEL_ADVANCE
]

const notRequiredSpecial = [
  CARDS.DIESEL_ADVANCE,
  CARDS.ATS_GOLD,
  CARDS.ATS_SILVER,
  CARDS.RHA,
  CARDS.FTA,
  CARDS.RHA_DIESEL,
  CARDS.KEYFUELS,
  CARDS.TEXACO,
  CARDS.SHELL_MULTI,
  CARDS.SHELL_CRT,
  CARDS.ESSO_COMMERCIAL,
  CARDS.ESSO_MULTI,
  CARDS.ESSO_SINGLE,
  CARDS.ALLSTAR_SUPERMARKET,
  CARDS.SHELL_COMMERCIAL,
]

const notRequiredQA = [
  CARDS.DIESEL_ADVANCE,
  CARDS.RHA,
  CARDS.FTA,
  CARDS.RHA_DIESEL,
  CARDS.TEXACO,
  CARDS.SHELL_MULTI,
  CARDS.SHELL_CRT,
  CARDS.ESSO_COMMERCIAL,
  CARDS.ESSO_MULTI,
  CARDS.ESSO_SINGLE,
  CARDS.ALLSTAR_NO_OFFER,
  CARDS.SHELL_COMMERCIAL
]

const notRequiredZeroLiability = [
  CARDS.DIESEL_ADVANCE,
  CARDS.ALLSTAR_SUPERMARKET,
  CARDS.SHELL_COMMERCIAL,
]

const notRequiredExpectedMonthlySpendFuel = [
  CARDS.ALLSTAR_EXPENSE,
  CARDS.ALLSTAR_SUPPLIER_PAY
]

const notRequiredCardType = [
  CARDS.ALLSTAR_EXPENSE,
  CARDS.ALLSTAR_SUPPLIER_PAY
]

const notRequiredPurchaseOptions = [
  CARDS.ALLSTAR_EXPENSE,
  CARDS.ALLSTAR_SUPPLIER_PAY
]

const notRequiredNumberOfCards = [
  CARDS.ALLSTAR_SUPPLIER_PAY
]

const notRequiredOnlineAccountText = [
  CARDS.DIESEL_ADVANCE,
  CARDS.RHA,
  CARDS.FTA,
  CARDS.RHA_DIESEL,
  CARDS.TEXACO,
  CARDS.SHELL_MULTI,
  CARDS.SHELL_CRT,
  CARDS.ESSO_COMMERCIAL,
  CARDS.ESSO_MULTI,
  CARDS.ESSO_SINGLE,
  CARDS.ALLSTAR_NO_OFFER,
  CARDS.SHELL_COMMERCIAL
]

const requiredTermsConditionsPopup = [
  CARDS.ATS_GOLD,
  CARDS.TRADE_POINT,
  CARDS.ATS_SILVER,
  CARDS.RHA,
  CARDS.FTA,
  CARDS.ARVAL,
  CARDS.RHA_DIESEL,
  CARDS.AA_FLEET
]

export {
  CARDS,
  dieselPrivacyPolicy,
  notRequiredPetrol,
  purchaseDieselOnly,
  notRequiredSpecial,
  notRequiredQA,
  notRequiredZeroLiability,
  allstarPrivacyPolicy,
  fuelCardPrivacyPolicy,
  keyfuelsPrivacyPolicy,
  requiredTermsConditionsPopup,
  fuelCardTermsConditions,
  allstarTermsConditions,
  notRequiredTermsConditionsField,
  notRequiredExpectedMonthlySpendFuel,
  notRequiredCardType,
  notRequiredPurchaseOptions,
  notRequiredNumberOfCards,
  notRequiredOnlineAccountText,
  cardsToHide
}