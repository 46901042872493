<template>
  <div class="container">
    <div class="row">
      <div class="col-12 my-3">
        <h2 class="">Thank you for your application</h2>
        <hr>
        <p>Your application reference is <strong>{{ query.AppRef }}</strong></p>
        <div class="main-content">
          <p>To ensure prompt delivery of your cards, we'll need a direct debit mandate (i.e. Account Name &amp; Number,
            Sort Code, and name of Bank) from you.</p>
          <p>Please follow the link below to fill out our direct debit form which will then be attached to your
            application form.</p>
          <p><a :href="query.DDLink" target="_blank" v-if="query.AppRef">{{ query.DDLink.split("?")[0] }}</a></p>
          <p>For your reference, we've also emailed the above instructions to the email address provided on our
            application form. A direct debit mandate is required before we open your account, so please provide your
            details as soon as possible.</p>
          <p>Once again many thanks for applying for our fuel cards. We look forward to welcoming you as a valued
            customer.</p>
          <p>Best regards</p>
          <p>The New Business Team&nbsp;<br>Phone: 0845 266 5101 &nbsp;<br>Email:&nbsp;<a
              href="mailto:fuelcardsales@allstarcard.co.uk">fuelcardsales@allstarcard.co.uk</a></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      query: this.$route.query,
    }
  },
  beforeMount () {
    if (!this.$route.query.AppRef) {
      this.$router.push({path: '/'})
    }
  }
}
</script>