
// import 'bootstrap'
window.axios = require('axios');

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import './assets/sass/app.module.scss'

createApp(App).use(router).mount('#app');
